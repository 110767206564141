import React from "react";
import { Route, Navigate } from "react-router-dom";
import { isLoggedIn } from "./utils/validateToken";

const PrivateRoute = ({ element: Element, ...rest }) => {
  console.log('isLoggedIn()', isLoggedIn())
  return (
    <Route
      {...rest}
      // element={isLoggedIn() ? <Element /> : <Navigate to="/" replace />}
      element={<Element /> }
    />
  );
};

export default PrivateRoute;
