import { initializeApp } from "firebase/app";

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: "biap-fe",
  storageBucket: "biap-fe.appspot.com",
  messagingSenderId : "857471349051",
  appId : "1:857471349051:web:2eab80cd98263c7322c71e"
});
