import styles from "./styles/globalStyles.module.scss";
import OndcRoutes from "./router";
import "./api/firebase-init";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div className={styles.background}>
          <Router>
      <OndcRoutes />
      </Router>
    </div>
  );
}

export default App;
