export const restoreToDefault = () => {
    return {
        name: "",
        email: "",
        phone: "",
        areaCode: "",
        city: "",
        door: "",
        state: "",
        street: "",
    }
}