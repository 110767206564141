import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AddressContextProvider } from "../../context/addressContext";
import { CartContextProvider } from "../../context/cartContext";
import PrivateRoute from "../../privateRoutes";
import Cart from "./cart/cart";
import Checkout from "./checkout/checkout";
import Orders from "./orders/orders";
import ProductList from "./product-list/productList";
import Profile from "./profile/profile";
import Support from "./support/support";
import ProductDetails from "./product-list/product-details/productDetails";
import InitializeOrder from "./initialize-order/initializeOrder";

export default function Application() {
  return (
    <CartContextProvider>
      <AddressContextProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/application/products" />} />
          <Route path="/products/:id" element={<ProductDetails />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/support" element={<Support />} />
          <Route path="/initialize" element={<InitializeOrder />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
      </AddressContextProvider>
    </CartContextProvider>
  );
}
